<script lang="ts">
import { 
  defineComponent,
  onMounted,
  onUnmounted,
  ref,
} from 'vue';
import {
  sendChatMessage,
  chatGroupsSelected,
} from '../../../store/chat';

export default defineComponent({
  name: 'ChatNewMessageContainer',
  setup() {
    const newMessage = ref(null);
    
    onMounted(() => {
      window.addEventListener('keydown', enterPressedHandler);
    });
    
    onUnmounted(() => {
      window.removeEventListener('keydown', enterPressedHandler);
    })

    const sendNewChatMessage = () => {
      sendChatMessage({
        to: chatGroupsSelected.value === 'all' ? null : chatGroupsSelected.value,
        message: newMessage.value
      });
      newMessage.value = null;
    };

    const enterPressedHandler = (e) => {
      if (e.key === 'Enter') {
        sendNewChatMessage();
      }
    };

    return {
      sendNewChatMessage,
      newMessage
    }
  },
})
</script>
<template>
<div id="new-message-container">
  <input id="new-message-input-container" v-model="newMessage"/>
  <div id="send-message-wrapper" @click="sendNewChatMessage">
    <img src="../../../assets/icons/send-message.svg"/>
  </div>
</div>
</template>
<style lang="scss" scoped>
#new-message-container {
  height: 48px;
  width: 100%;
  justify-content: space-between;
  display: flex;
  flex-direction: row;
  align-items: center;
  #new-message-input-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0;
    width: 180px;
    height: 34px;
    background: #F2F2F5;
    border-radius: 100px;
    margin: 0 10px;
    border: none;
    padding: 0 8px;
  }
  #send-message-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0;
    width: 48px;
    height: 48px;
    box-shadow: none;
    border-radius: 100px;
    transition: all .2s ease-in-out;
  }
}
</style>