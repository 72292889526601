<script lang="ts">
import { 
  defineComponent, 
  onMounted, 
  onUpdated, 
  ref, 
} from 'vue';
import { 
  messagesByGroupSelected,
} from '../../../store/chat';

export default defineComponent({
  name: 'ChatMessageContainer',
  setup() {
    const formattedMessages = ref([]);
    const scroll = ref(null);

    onMounted(() => {
      scrollToBottom();
    });

    onUpdated(() => {
      scrollToBottom();
    });

    const scrollToBottom = () => { scroll.value.scrollTop = scroll.value.scrollHeight };

    return {
      messagesByGroupSelected,
      scroll
    }
  },
});
</script>
<template>
<div id="chat-message-container">
  <div ref="scroll" id="chat-messages-scrollable">
    <div v-bind:key="index" v-for="(messageGroup, index) of messagesByGroupSelected" class="message-group">
      <p class="sender-name-heading">
        {{messageGroup.name}}
      </p>
      <div v-bind:key="indexx" v-for="(message, indexx) of messageGroup.messages" :style="{'background-color': messageGroup.color}" class="message-group-message">
        {{message.message}}
      </div>
    </div>
  </div>
</div>
</template>
<style lang="scss" scoped>
#chat-message-container {
  height: 250px;
  width: 100%;
  #chat-messages-scrollable {
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    .message-group {
      display: flex;
      flex-direction: column;
      .sender-name-heading {
        font-family: 'Gilroy';
        font-style: normal;
        font-weight: 500;
        font-size: 10px;
        line-height: 14px;
        display: flex;
        align-items: center;
        letter-spacing: 0.2px;
        color: #C7C9D9;
        margin: 0 10px;
      }
      .message-group-message {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        padding: 8px 16px;
        background: #FEB254;
        box-shadow: 0px 0px 1px rgba(40, 41, 61, 0.04), 0px 0.5px 2px rgba(96, 97, 112, 0.08);
        border-radius: 10px 10px 6px 6px;
        margin: 4px 0px;
      }
    }
  }
}
</style>