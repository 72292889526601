

import { defineComponent, onMounted, ref, watch } from 'vue';
import ChatHeader from './components/ChatHeader.vue';
import ChatMessageContainer from './components/ChatMessageContainer.vue';
import ChatNewMessageContainer from './components/ChatNewMessageContainer.vue';
import { callActive } from '../../store/session';
import {
  open,
  toggleChatWindow,

} from '../../store/chat';

export default defineComponent({
  name: 'ChatPopover',
  components: {
    ChatHeader,
    ChatMessageContainer,
    ChatNewMessageContainer,
  },
  setup() {
    return {
      open,
      toggleChatWindow
    }
  }
})
