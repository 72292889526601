
<script lang='ts'>
import { defineComponent, ref, toRefs } from 'vue';
import CallDrawer from '../../components/CallDrawer/CallDrawer.vue';
import ChatPopover from '../../components/ChatPopover/ChatPopover.vue';
import { whiteboardEnabled } from '../../store/views';
import {
  setUserId,
  setUserType,
  clinician,
  setIdentifyingInfo,
} from '../../store/user';
import { attendeeCount } from '../../store/attendees';
import { callActive, connected } from '../../store/session';
import CallActions from '../../utils/CallActions';

export default defineComponent({
  name: 'Home',
  props: {
    meetingId: {
      type: String,
      required: true,
    },
    tokenInfo: {
      type: String,
      required: true,
    },
  },
  components: {
    CallDrawer,
    ChatPopover,
  },
  setup(props) {
    const sessionId = ref(null);
    const token = ref(null);
    const { meetingId, tokenInfo } = toRefs(props);
    setIdentifyingInfo(tokenInfo.value as string);

    const callCreds = {
      meeting_id: meetingId.value,
    };

    CallActions.joinCall(callCreds).then((meeting) => {
      console.log(meeting);
      token.value = meeting.attributes.session_token;
      sessionId.value = meeting.relationships.session.video_session_id;
      setUserId(meeting.attributes.user_id);
      setUserType(meeting.attributes.role);
    });
    return {
      sessionId,
      token,
      callActive,
      connected,
      clinician,
      attendeeCount,
      whiteboardEnabled,
    };
  },
});
</script>

<template>
  <div class="home">
    <CallHeader />
    <div class="main-body">
      <Session
        v-if="sessionId"
        :meeting-id="meetingId"
        :session-id="sessionId"
        :token="token"
      />
      <template v-if="!callActive && connected">
        <div class="background-text">
          <div class="text-box">
            <p class="big-text">We’re getting everything ready.</p>
            <p class="little-text">Your meeting will start shortly.</p>
          </div>
        </div>
        <div class="working-guy-background"></div>
        <CallPreview></CallPreview>
      </template>
      <template v-if="callActive && clinician">
        <div
          v-if="!attendeeCount && !whiteboardEnabled"
          class="background-text waiting-for-attendees"
        >
          <div class="text-box">
            <p class="big-text">
              You are now waiting for participants to join.
            </p>
            <p class="little-text">
              As soon as they are ready, approve them on the left.
            </p>
          </div>
        </div>
        <CallDrawer></CallDrawer>
      </template>
    </div>
    <ChatPopover v-if="callActive"></ChatPopover>
  </div>
</template>

<style lang='scss' scoped>
.home {
  display: flex;
  flex-direction: column;
  background-image: url('../../assets/Vector.svg');
  background-repeat: no-repeat;
  background-position: bottom right;
  .main-body {
    display: flex;
    .working-guy-background {
      position: absolute;
      top: 80px;
      width: 100%;
      display: flex;
      background-image: url('../../assets/light.svg');
      background-repeat: no-repeat;
      background-position: 5% center;
      height: calc(100vh - 65px);
    }
    .background-text {
      width: 591px;
      height: 330px;
      position: absolute;
      top: 37%;
      left: 13%;
      background-color: #fafafc;
      .text-box {
        width: 420px;
        text-align: left;
        float: right;
        height: 100%;
        padding: 24px;
        .little-text {
          font-family: Gilroy;
          font-style: normal;
          font-weight: 600;
          font-size: 32px;
          line-height: 38px;
          font-feature-settings: 'pnum' on, 'lnum' on;
          color: #8f90a6;
        }
        .big-text {
          font-family: Gilroy;
          font-style: normal;
          font-weight: bold;
          font-size: 40px;
          line-height: 48px;
          font-feature-settings: 'pnum' on, 'lnum' on;
          color: #555770;
        }
      }
      &.waiting-for-attendees {
        left: 29%;
        .text-box {
          width: auto;
        }
      }
    }
  }
}
</style>