
import {
  defineComponent,
  onMounted,
  ref,
  watch
} from 'vue';
import {
  // open,
  chatGroups,
  chatGroupsSelected,
  setChatGroupSelected,
  toggleChatWindow,
} from '../../../store/chat';

import Menu from 'primevue/menu';

export default defineComponent({
  name: 'ChatHeader',
  components: {
    Menu
  },
  setup() {
    const menu = ref(null);
    const chatGroupSelectedMenuData = ref([]);
    const chatGroupSelectedName = ref('All');

    onMounted(() => {
      updateMenuData();
    });

    watch([() => chatGroups, chatGroupsSelected], () => {
      updateMenuData();
    }, { deep: true});

    const updateMenuData = () => {
      chatGroupSelectedMenuData.value = [];
      for (const [key, value] of Object.entries(chatGroups.value)) {
        chatGroupSelectedMenuData.value.push({label: value.name, icon: key === chatGroupsSelected.value ? 'pi pi-check' : null, command: () => setChatGroupSelected(key)});
      }
      chatGroupSelectedName.value = chatGroups.value[chatGroupsSelected.value].name;
    };

    const toggleChatGroupsList = toggle => {
      menu.value.toggle(toggle)
    };

    return {
      menu,
      toggleChatWindow,
      chatGroupSelectedName,
      chatGroupSelectedMenuData,
      toggleChatGroupsList
    }
  },
})
