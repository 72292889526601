<script lang="ts">
import {
  defineComponent,
  onMounted,
  ref,
  watch
} from 'vue';
import {
  // open,
  chatGroups,
  chatGroupsSelected,
  setChatGroupSelected,
  toggleChatWindow,
} from '../../../store/chat';

import Menu from 'primevue/menu';

export default defineComponent({
  name: 'ChatHeader',
  components: {
    Menu
  },
  setup() {
    const menu = ref(null);
    const chatGroupSelectedMenuData = ref([]);
    const chatGroupSelectedName = ref('All');

    onMounted(() => {
      updateMenuData();
    });

    watch([() => chatGroups, chatGroupsSelected], () => {
      updateMenuData();
    }, { deep: true});

    const updateMenuData = () => {
      chatGroupSelectedMenuData.value = [];
      for (const [key, value] of Object.entries(chatGroups.value)) {
        chatGroupSelectedMenuData.value.push({label: value.name, icon: key === chatGroupsSelected.value ? 'pi pi-check' : null, command: () => setChatGroupSelected(key)});
      }
      chatGroupSelectedName.value = chatGroups.value[chatGroupsSelected.value].name;
    };

    const toggleChatGroupsList = toggle => {
      menu.value.toggle(toggle)
    };

    return {
      menu,
      toggleChatWindow,
      chatGroupSelectedName,
      chatGroupSelectedMenuData,
      toggleChatGroupsList
    }
  },
})
</script>
<template>
<div id="chat-header">
  <div id="chat-icon-wrapper">
    <img src="../../../assets/icons/chat.svg">
  </div>
  <span id="chat-header-title">Chat</span>
  <div id="chat-group-selector-container" @click="toggleChatGroupsList">
    <span id="chat-group-selected">To: {{chatGroupSelectedName}}</span>
    <i class="pi pi-angle-down"/>
    <Menu
      v-if="chatGroupSelectedMenuData.length"
      ref="menu"
      id="chat-group-picker"
      :model="chatGroupSelectedMenuData"
      :popup="true"
      :appendTo="'body'"
    />
  </div>
  <div id="close-icon-wrapper" @click="toggleChatWindow(false)">
    <img src="../../../assets/icons/chat-close.svg"/>
  </div>
</div>
</template>
<style lang="scss" scoped>
#chat-header {
  height: 48px;
  width: 100%;
  justify-content: space-between;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px #F2F2F5 solid;
  #chat-icon-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0;
    width: 48px;
    height: 48px;
    // background: #fff;
    box-shadow: none;
    border-radius: 100px;
    transition: all .2s ease-in-out;
  }
  #chat-header-title {
    margin-right: auto;
  }
  #chat-group-selector-container {
    .pi-angle-down {
      align-self: center;
    }
  }
  #close-icon-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0;
    width: 48px;
    height: 48px;
    // background: #fff;
    box-shadow: none;
    border-radius: 100px;
    transition: all .2s ease-in-out;
  }
}
</style>