
import { defineComponent, ref, toRefs } from 'vue';
import CallDrawer from '../../components/CallDrawer/CallDrawer.vue';
import ChatPopover from '../../components/ChatPopover/ChatPopover.vue';
import { whiteboardEnabled } from '../../store/views';
import {
  setUserId,
  setUserType,
  clinician,
  setIdentifyingInfo,
} from '../../store/user';
import { attendeeCount } from '../../store/attendees';
import { callActive, connected } from '../../store/session';
import CallActions from '../../utils/CallActions';

export default defineComponent({
  name: 'Home',
  props: {
    meetingId: {
      type: String,
      required: true,
    },
    tokenInfo: {
      type: String,
      required: true,
    },
  },
  components: {
    CallDrawer,
    ChatPopover,
  },
  setup(props) {
    const sessionId = ref(null);
    const token = ref(null);
    const { meetingId, tokenInfo } = toRefs(props);
    setIdentifyingInfo(tokenInfo.value as string);

    const callCreds = {
      meeting_id: meetingId.value,
    };

    CallActions.joinCall(callCreds).then((meeting) => {
      console.log(meeting);
      token.value = meeting.attributes.session_token;
      sessionId.value = meeting.relationships.session.video_session_id;
      setUserId(meeting.attributes.user_id);
      setUserType(meeting.attributes.role);
    });
    return {
      sessionId,
      token,
      callActive,
      connected,
      clinician,
      attendeeCount,
      whiteboardEnabled,
    };
  },
});
