<script lang="ts">

import { defineComponent, onMounted, ref, watch } from 'vue';
import ChatHeader from './components/ChatHeader.vue';
import ChatMessageContainer from './components/ChatMessageContainer.vue';
import ChatNewMessageContainer from './components/ChatNewMessageContainer.vue';
import { callActive } from '../../store/session';
import {
  open,
  toggleChatWindow,

} from '../../store/chat';

export default defineComponent({
  name: 'ChatPopover',
  components: {
    ChatHeader,
    ChatMessageContainer,
    ChatNewMessageContainer,
  },
  setup() {
    return {
      open,
      toggleChatWindow
    }
  }
})
</script>
<template>
<div id="chat-container" :class="{open: open, close: !open}">
  <template v-if="!open">
    <div id="chat-icon-wrapper" @click="toggleChatWindow(true)">
      <img src="../../assets/icons/chat.svg">
    </div>
  </template>
  <template v-else>
    <ChatHeader/>
    <ChatMessageContainer/>
    <ChatNewMessageContainer/>
  </template>

</div>
</template>
<style lang="scss" scoped>
#chat-container {
  display: flex;
  position: absolute;
  bottom: 24px;
  right: 24px;
  margin: 0px 24px;
  transition: all .2s ease-in-out;
  &.open {
    flex-direction: column;
    align-items: flex-start;
    padding: 0;
    width: 250px;
    height: 350px;
    background: #FFFFFF;
    border-radius: 10px;
    box-shadow: 0px 2px 8px rgba(40, 41, 61, 0.08), 0px 20px 32px rgba(96, 97, 112, 0.24);
    transition: all .2s ease-in-out;
  }
  &.close {
    #chat-icon-wrapper {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 0;
      width: 48px;
      height: 48px;
      background: #fff;
      box-shadow: none;
      border-radius: 100px;
      transition: all .2s ease-in-out;
    }
  }
}
</style>