
import { 
  defineComponent, 
  onMounted, 
  onUpdated, 
  ref, 
} from 'vue';
import { 
  messagesByGroupSelected,
} from '../../../store/chat';

export default defineComponent({
  name: 'ChatMessageContainer',
  setup() {
    const formattedMessages = ref([]);
    const scroll = ref(null);

    onMounted(() => {
      scrollToBottom();
    });

    onUpdated(() => {
      scrollToBottom();
    });

    const scrollToBottom = () => { scroll.value.scrollTop = scroll.value.scrollHeight };

    return {
      messagesByGroupSelected,
      scroll
    }
  },
});
