
import { 
  defineComponent,
  onMounted,
  onUnmounted,
  ref,
} from 'vue';
import {
  sendChatMessage,
  chatGroupsSelected,
} from '../../../store/chat';

export default defineComponent({
  name: 'ChatNewMessageContainer',
  setup() {
    const newMessage = ref(null);
    
    onMounted(() => {
      window.addEventListener('keydown', enterPressedHandler);
    });
    
    onUnmounted(() => {
      window.removeEventListener('keydown', enterPressedHandler);
    })

    const sendNewChatMessage = () => {
      sendChatMessage({
        to: chatGroupsSelected.value === 'all' ? null : chatGroupsSelected.value,
        message: newMessage.value
      });
      newMessage.value = null;
    };

    const enterPressedHandler = (e) => {
      if (e.key === 'Enter') {
        sendNewChatMessage();
      }
    };

    return {
      sendNewChatMessage,
      newMessage
    }
  },
})
